.left-nav-container {
    position: relative;
    .icons {
        //position: fixed;
        z-index: 10;
        @media screen and(max-width: 590px) {
            margin: 2px 0 0 -9px;
        }
        .icons-bis {
            display: flex;
            left: 10px;
            a {
                transition: 0.1s;
                //display: inline-block;
                margin-left: 10px;
                // &:before {
                //     transition: .15s ease;
                //     transform: scale(0);
                //     transform-origin: center;
                //     z-index: -10;
                //     content: "";
                //     height: 30px;
                //     width: 30px;
                //     position: absolute;
                //     background: $color-3;
                //     border-radius: 20px;
                // }
                // &:hover {
                //     transform: scale(1.02);
                // }
                &:hover::before {
                    transform: scale(1);
                    @media screen and(max-width: 590px) {
                        background: $color-1;
                        height: 34px;
                        width: 34px;
                    }
                }
                img {
                    @media screen and(max-width: 590px) {
                        height: 34px;
                        width: 34px;
                    }
                }
            }
            .active-left-nav {
                position: relative;
                &::after {
                    content: "";
                    height: 5px;
                    width: 21px;
                    position: absolute;
                    background: $color-1;
                    left: 1px;
                    bottom: -20px;
                    border-radius: 40px;
                    transform: scaleY(0);
                    animation: scale 1.5s ease forwards;
                    @keyframes scale {
                        to {
                            transform: scaleY(1);
                        }
                    }
                    @media screen and(max-width: 590px) {
                        height: 5px;
                    }
                }
            }
        }
    }
}