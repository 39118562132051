nav {
    position: fixed;
    width: 100%;
    height: 60px;
    border-radius: 6px;
    //box-shadow: 18px 1px 0px $color-1, 22px 4px 0px 0px $shadow;
    box-shadow: $shadow;
    z-index: 10;
    top: 0;
    @media screen and(max-width: 1251px) {
        padding: 0 20px;
    }
    &::after {
        content: "";
        height: 60px;
        width: 110%;
        left: -20px;
        top: 0;
        position: absolute;
        backdrop-filter: blur(3px);
        background: rgba(253, 253, 253, 0.86);
        z-index: -8;
    }
    .nav-container {
        display: flex;
        width: 100%;
        max-width: 1190px;
        margin: 0 auto;
        justify-content: space-between;
        height: 60px;
        align-items: center;
        .logo {
            display: flex;
            align-items: center;
            h3 {
                transform: translate(15px, 2px);
                font-size: 1.5rem;
                font-family: $font-3;
            }
            img {
                width: 24%;
                @media screen and(max-width: 768px) {
                    width: 50%;
                }
                @media screen and(max-width: 590px) {
                    width: 50%;
                }
            }
        }
        ul {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 35%;
            justify-content: flex-end;
            .welcome {
                transform: translateY(2px);
                @media screen and(max-width: 590px) {
                    display: none;
                }
                h5 {
                    font-family: $font-3;
                }
            }
            li {
                margin-left: 20px;
                .fas.fa-sign-out-alt {
                    cursor: pointer;
                }
            }
        }
    }
}