.connection-from {
    border: 2px solid $color-1;
    width: 400px;
    margin: 35px auto 0;
    padding: 20px 0;
    user-select: none;
    text-align: center;
    h3 {
        padding: 10px 0 15px;
    }
    ul {
        padding: 10px;
        display: flex;
        justify-content: space-around;
        li {
            background: $color-2;
            color: $white;
            width: 130px;
            padding: 10px 0;
            border-radius: 18px;
            cursor: pointer;
            transition: 0.3s;
            box-shadow: 3px 3px 1px rgba(51, 51, 51, 0.418);
            &:hover {
                background: $color-1;
            }
        }
    }
    .active-btn {
        background: $color-1;
    }
    form {
        width: 400px;
        margin: 10px auto 0;
        padding: 20px;
    }
}